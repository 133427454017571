<template>
    <el-dialog class="time-dialog" v-model="$store.state.validityVisible" width="602">
        <div class="time-header"><img src="@/assets/image/time/header.png" alt=""></div>
        <div class="time-close" @click="close"><img src="@/assets/image/time/close.png" alt=""></div>
        <div class="time-content">
            <div class="content-info">
                亲爱的用户，您的账号将在{{ $store.state.validityNumber }}天后到期。我们期待与您继续携手前行。如需延期账号或有任何问题，欢迎随时联系我们。
            </div>
            <div class="content-tag">
                <div class="tag-title">我们的联系方式</div>
                <div class="tag-fixbox">
                    <div class="fixbox-line">联系电话：16601756258</div>
                    <div class="fixbox-line">联系邮箱：zaitaofeibing@icccall.com</div>
                </div>
            </div>
        </div>
    </el-dialog>
</template>
<script>
export default {
    data() {
        return {
            dialogVisible: false,
        }
    },
    methods: {
        close() {
            this.$store.commit("setVisible", false);

        }
    }
}
</script>
<style lang="less">
.time-dialog {
    background: transparent !important;

    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        padding: 0;
        border-radius: 16px;
        overflow: hidden;
        position: relative;

        .time-header {
            img {
                display: block;
                width: 100%;
            }
        }

        .time-close {
            position: absolute;
            right: 27px;
            top: 22px;
            cursor: pointer;

            img {
                width: 14px;
                display: block;
            }
        }

        .time-content {
            background: #FFFFFF;
            padding: 19px 68px;
            box-sizing: border-box;

            .content-info {
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #4D5464;
                line-height: 22px;
                text-align: center;
            }

            .content-tag {
                margin-top: 25px;
                background: #F9F9F9;
                border-radius: 4px;
                padding: 13px 20px 9px;

                .tag-title {
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 600;
                    font-size: 14px;
                    color: #202020;
                    line-height: 20px;
                }

                .tag-fixbox {
                    margin-top: 14px;
                    .fixbox-line {
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 400;
                        font-size: 14px;
                        color: #202020;
                        line-height: 20px;
                        margin-bottom: 8px;
                    }
                }
            }
        }
    }
}
</style>