<template>
  <el-config-provider :locale="locale">
    <router-view />
  </el-config-provider>

</template>

<script>
import { ElConfigProvider } from 'element-plus'
// 引入中文包
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
export default {
  name: "App",
  components: {
    [ElConfigProvider.name]: ElConfigProvider
  },
  setup() {
    const lang = () => {
      location.reload()
    }
    let locale = zhCn
    return {
      locale
    }
  },
  mounted() {
    // console.log(process.env.VUE_APP_BASE_API);
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  box-sizing: border-box;
  background: @colorf6f6f6;
}
</style>
