/**
 * 全局公共方法
 */
import { ElMessage } from 'element-plus';
/**获取图片的实际的长度 */
function getImgAttr(url, callback) {
    let img = new Image()
    img.src = url
    if (img.complete) {
        callback(img.width, img.height);
    } else {
        img.onload = function () {
            callback(img.width, img.height)
            img.onload = null
        }
    }
}

/**手机号表单验证 */
function mobileRules(rule, value, callback) {
    if (value === "") {
        callback(new Error("请输入手机号"));
    } else if (!RegExp("^1\\d{10}$").test(value)) {
        callback(new Error("手机号格式错误"));
    } else {
        callback();
    }
}
/**时间转化 */
function renderTime(date) {
    let dateee = new Date(date).toJSON();
    return new Date(+new Date(dateee) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "");
}
/** 字符串是否为空、空格 */
function isNull(str) {
    if (str == "") return true;
    let regu = "^[ ]+$";
    let re = new RegExp(regu);
    return re.test(str);
}

/**下载 */
function downloadFiles(url, name) {
    let link = document.createElement('a');
    let suffix = '';
    // 这里将url转成blob地址
    fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
        link.href = URL.createObjectURL(blob);
        // if (navigator.userAgent.indexOf('Firefox') >= 0) {
            if (url.lastIndexOf('.pdf') != -1) {
                suffix = '.pdf'
                link.target = '_blank'
                link.download = name + suffix; // 下载的名称
                window.open(url);

            } else if (url.lastIndexOf('.docx') != -1) {
                suffix = '.docx'
                link.download = name + suffix; // 下载的名称
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else if (url.lastIndexOf('.xls') != -1) {
                suffix = '.xls'
                link.download = name + suffix; // 下载的名称
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else if (url.lastIndexOf('.jpg') != -1) {
                suffix = '.jpg'
                link.download = name + suffix; // 下载的名称
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else if (url.lastIndexOf('.png') != -1) {
                suffix = '.png'
                link.download = name + suffix; // 下载的名称
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }

        // } else {
        //     link.download = name; // 下载的名称
        //     document.body.appendChild(link);
        //     link.click();
        //     document.body.removeChild(link);
        // }

    })
}

/** 复制 */
function copy(txt) {
    var dom = document.createElement("div");
    dom.className = "books";
    dom.innerHTML = txt;
    document.body.appendChild(dom);
    // return false;
    var el = dom;
    var range,
        sel;
    if (document.createRange && window.getSelection) {
        range = document.createRange();
        sel = window.getSelection();
        sel.removeAllRanges();
        try {
            range.selectNodeContents(el);
            sel.addRange(range);
        } catch (e) {
            range.selectNode(el);
            sel.addRange(range);
        }
        document.execCommand("copy");
        //取消文本选中状态
        window.getSelection().empty();
        // alert("成功复制到剪贴板");
    }
    document.body.removeChild(dom);

    ElMessage({
        message: '复制成功！',
        type: 'success',
    })
}

/**过滤html标签 */
function filterHTML(text) {
    let regex = /(<([^>]+)>)/ig
    return text.replace(regex, "");
}


/**过滤html标签内的class style */
function filterClassStyle(text) {
    return text.replace(new RegExp("<style.+style>", "g"), "")
        .replace(new RegExp("<head>", "g"), "")
        .replace(new RegExp("</head>", "g"), "")
        .replace(new RegExp("<body>", "g"), "")
        .replace(new RegExp("</body>", "g"), "")
        .replace(new RegExp('(div style="(.*?)")', "ig"), "div")
        .replace(new RegExp('(tr style="(.*?)")', "ig"), "tr");
}

//导出
export default {
    getImgAttr: getImgAttr,
    mobileRules: mobileRules,
    renderTime: renderTime,
    isNull: isNull,
    downloadFiles: downloadFiles,
    copy: copy,
    filterHTML: filterHTML,
    filterClassStyle: filterClassStyle
}