import axios from 'axios'
import { ElMessageBox, ElMessage } from 'element-plus';
import store from '../store' // 公共参数

let loginStatus = true;
// import cookie from './cookie';
// 创建一个 axios 实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // 所有的请求地址前缀部分
    // baseURL: '', // 所有的请求地址前缀部分
    timeout: 720000, // 请求超时时间毫秒
    withCredentials: true, // 异步请求携带cookie
    headers: {
        // 设置后端需要的传参类型
        'Content-Type': 'application/json;charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest',
    },
})

// 添加请求拦截器
service.interceptors.request.use(
    (config) => {
        // 在发送请求之前做些什么
        return config
    },
    (error) => {
        // 对请求错误做些什么
        return Promise.reject(error)
    }
)

// 添加响应拦截器
service.interceptors.response.use(
    (response) => {
        console.log()
        // 2xx 范围内的状态码都会触发该函数。
        // 对响应数据做点什么
        // dataAxios 是 axios 返回数据中的 data
        const dataAxios = response.data
        const responseURL = response.request.responseURL
        // 这个状态码是和后端约定的
        const code = dataAxios.code;
        const msg = dataAxios.message
        if (code == '0' || code == "200" || code == undefined) {
            return dataAxios
        } else if (code === '133') {
            if (loginStatus) {
                store.dispatch('loginOutFn', '登录状态已过期，')
                loginStatus = false;
                return Promise.reject('无效的会话，或者会话已过期，请重新登录。');
            }

        } else {
            if (responseURL.indexOf('/api_exWord/wordImport') != -1) {
                return dataAxios
            } else if (responseURL.indexOf('/api_custom/findCSDetails') != -1) {
                // 添加关键词
                return dataAxios
            }


            // 报错
            ElMessage({ message: msg, type: 'error' });
            return Promise.reject(msg);

        }
        // console.log(dataAxios);
    },
    (error) => {
        // 超出 2xx 范围的状态码都会触发该函数。
        // 对响应错误做点什么
        return Promise.reject(error)
    }
)

export default service
