/**
 * 配置侧边栏目录
 * 
 */

const sidebar = [
    {
        name: '在线校对',
        icon: 'Memo', // 图标
        url: 'proofreading', // 主目录
        msg: '', // 释义
        show: false, // 是否显示
        children: [{
            name: '文本校对',
            icon: '',
            url: '/proofreading/text',
            msg: '',
            children: []
        }, {
            name: '图片校对',
            icon: '',
            url: '/proofreading/img',
            msg: '',
            children: []
        },
        {
            name: '文件列表',
            icon: '',
            url: '/proofreading/fileList',
            msg: '',
            children: []
        }, {
            name: '词库管理',
            icon: '',
            url: 'lexicon',
            msg: '',
            children: [{
                name: '领导人词库',
                icon: '',
                url: '/lexicon/lingdaoren',
                msg: '可通过地域、机构职务、名称添加相关数据。',
                children: []
            }, {
                name: '报道顺序',
                icon: '',
                url: '/lexicon/baodaoshunxu',
                msg: '可通过姓名设置审核顺序。',
                children: [],
                new: true,
            }, {
                name: '正词',
                icon: '',
                url: '/lexicon/zhengci',
                msg: '正词是指在文章中，被误判的词，如“是茶颜悦色打卡排队的用户”，这里面“茶颜悦色”为正确词汇，当文章中出现此词汇时，系统将不做错误提示。',
                children: []
            }, {
                name: '错词',
                icon: '',
                url: '/lexicon/cuoci',
                msg: '错词是指用词易混淆的型近、音近等词汇，如“数据”和“数椐”，用户可将“数椐”设置为错词，将“数据”设置为正词，同一个正词可对应多个错词。当文章内容中出现“数椐”时，系统会高亮提示并建议替换其为“数据”。',
                children: []
            }, {
                name: '禁用词',
                icon: '',
                url: '/lexicon/jinyongci',
                msg: '禁用词是指用户自定义的，在自己文稿中不允许使用的词汇，如当用户设置“买卖”为禁用词时，当用户校对的文章中出现“买卖”时，系统会高亮显示，提醒用户。',
                children: []
            }, {
                name: '重点关注',
                icon: '',
                url: '/lexicon/zhongdianguanzhu',
                msg: '此为用户重点关注的词汇，当用户设置了重点关注词汇后，系统会严格检测该词汇的用法，并且高亮显示该词。',
                children: []
            }, {
                name: '风险人员',
                icon: '',
                url: '/lexicon/fengxianrenyuan',
                msg: '可通过地域、机构职务、名称添加相关数据',
                children: []
            }]
        }]
    }, {
        name: '媒体巡检',
        icon: 'DataAnalysis',
        url: 'media',
        msg: '',
        show: false,
        children: [{
            name: '巡检总览',
            icon: '',
            url: '/media/overView',
            msg: '',
            children: []
        }, {
            name: '巡检列表',
            icon: '',
            url: '',
            msg: '',
            children: [{
                name: '特别关注',
                icon: '',
                url: '/media/tbgz',
                msg: '在系统设置自定义关键词内添加词汇后，对巡检源发布的文章中包含设定关键词的数据展示',
                children: []
            }, {
                name: '内容更新',
                icon: '',
                url: '/media/nrgx',
                msg: '所有巡检源更新数据的展示',
                children: []
            }, {
                name: '安全攻击',
                icon: '',
                url: '/media/aqgj',
                msg: '对巡检源受到攻击、篡改的数据展示',
                children: []
            }, {
                name: '访问异常',
                icon: '',
                url: '/media/fwyc',
                msg: '巡检源发布的文章中涉及到的访问异常、请求错误的数据展示',
                children: []
            }, {
                name: '内容检测',
                icon: '',
                url: '/media/nrjc',
                msg: '巡检源发布的文章中涉及到政治敏感、违规违禁、错字、别字、标点错误等内容的数据展示',
                children: []
            }, {
                name: '附件检测',
                icon: '',
                url: '/media/fjjc',
                msg: '巡检源发布的文章中获取到的附件文件的数据展示',
                children: []
            }]
        }, {
            name: '巡检源管理',
            icon: '',
            url: '/media/pollingSource',
            msg: '',
            children: []
        }, {
            name: '系统设置',
            icon: '',
            url: '/media/systemSetup',
            msg: '',
            children: []
        }]
    }, {
        name: '常见工具',
        icon: 'FirstAidKit',
        url: 'tools',
        msg: '',
        show: false,
        children: [{
            name: '文档转化',
            icon: '',
            url: '/tool/pdf&word',
            msg: '',
            children: []
        }, {
            name: '语音听写',
            icon: '',
            url: '/tool/audio&txt',
            msg: '',
            children: []
        }, {
            name: '水印添加',
            icon: '',
            url: '/tool/img&watermark',
            msg: '',
            children: []
        }, {
            name: '繁简转换',
            icon: '',
            url: '/tool/complex&familiar',
            msg: '',
            children: []
        }, {
            name: '中外互译',
            icon: '',
            url: '/tool/multilingual&translation',
            msg: '',
            children: []
        },]
    }, {
        name: 'AI灵感',
        icon: 'FirstAidKit',
        url: '/proofreading/inspiration',
        msg: '',
        show: false,
        children: []
    }
    // , {
    //     name: '文章仿写',
    //     icon: 'FirstAidKit',
    //     url: '/proofreading/communication',
    //     msg: '',
    //     show: true,
    //     children: []
    // }
];



export default sidebar