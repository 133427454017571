<template>
    <div class="t-header flex-space-between animation-All" :style="{ 'padding-left': paddingLeft + 'px' }">
        <div class="header-logo animation-All" v-if="$store.state.domain" :style="{ width: paddingLeft + 'px' }">
            <el-image :src="$store.state.domain.domain_logo_url_home"></el-image>
        </div>
        <div class="header-icon flex-start">
            <el-icon :size="32" color="#ccc" v-if="openSidebar" @click="handleSelect">
                <Fold />
            </el-icon>
            <el-icon :size="32" color="#ccc" @click="handleSelect" v-else>
                <Expand />
            </el-icon>

            <!-- 音频转化 -->
            <audio-vue v-if="
                $store.state.userInfo.customer_level == 4 ||
                $store.state.userInfo.customer_level == 5 ||
                $store.state.userInfo.customer_level == 6 ||
                $store.state.userInfo.customer_level == 7 && !$domain.suobei
            "></audio-vue>
        </div>
        <div class="header-box flex-end" v-if="userInfo">
            <div class="box-line flex-start box-line-box" @click="wpsDow" v-if="isWps">
                <div class="line-img">
                    <img src="@/assets/image/yidongHome/xiazai.jpg" alt="" />
                </div>
                <div class="line-html">WPS插件下载</div>
            </div>
            <div class="box-line flex-start">
                <div class="line-icon">
                    <el-icon :size="24">
                        <UserFilled />
                    </el-icon>
                </div>
                <div class="line-text" v-html="userInfo.account"></div>
            </div>
            <div class="box-line flex-start">
                <div class="line-icon">
                    <el-icon :size="24">
                        <Stopwatch />
                    </el-icon>
                </div>
                <div class="line-text">
                    <el-popover placement="bottom-start" title="信源详情" :width="400" trigger="hover" :content="'微信：' +
                        userInfo.wechat_num +
                        '/' +
                        userInfo.wechat_total +
                        '；微博：' +
                        userInfo.weibo_num +
                        '/' +
                        userInfo.weibo_total +
                        '；网站：' +
                        userInfo.website_num +
                        '/' +
                        userInfo.website_total +
                        '；视频号：' +
                        userInfo.video_num +
                        '/' +
                        userInfo.video_total +
                        '；自媒体：' +
                        userInfo.media_num +
                        '/' +
                        userInfo.media_total
                        ">
                        <template #reference>
                            可用信源：{{
                                userInfo.website_num +
                                userInfo.wechat_num +
                                userInfo.weibo_num +
                                userInfo.video_num +
                                userInfo.media_num
                            }}/{{ userInfo.total_source }}
                        </template>
                    </el-popover>
                </div>
            </div>
            <div class="box-line flex-start" v-if="!$domain.gdlt_10010">
                <div class="line-icon">
                    <el-icon :size="24">
                        <Timer />
                    </el-icon>
                </div>

                <div class="line-text">
                    到期：{{ $common.renderTime(userInfo.validity_time) }}
                </div>
            </div>
            <el-popover placement="bottom" :width="200" trigger="hover" @show="creatQrCode" @hide="creatQrCode_rem">
                <template #reference>
                    <div class="box-line flex-start" v-if="!$domain.suobei">
                        <div class="line-icon">
                            <el-icon :size="24">
                                <Download />
                            </el-icon>
                        </div>
                        <div class="line-text">APP下载</div>
                    </div>
                </template>

                <template #default>
                    <div>
                        <div class="font-12 lineHeight2">请使用安卓手机浏览器扫码安装</div>
                        <qrcode-vue :value="value" :size="size" level="H" />
                    </div>
                </template>
            </el-popover>
            <!-- <el-popover
        placement="bottom"
        :width="200"
        trigger="hover"
        @show="creatQrCode"
        @hide="creatQrCode_rem"
      >
        <template #reference>
          <div class="box-line flex-start">
            <div class="line-icon">
              <el-icon :size="24">
                <FullScreen />
              </el-icon>
            </div>
            <div class="line-text">扫码绑定</div>
          </div>
        </template>

        <template #default>
          <div>
            <div class="font-12 lineHeight2">请使用微信扫码绑定</div>
            <img style="width: 100%" :src="pathUrl" alt="" />
          </div>
        </template>
      </el-popover> -->

            <div class="box-line flex-start">
                <div class="line-icon">
                    <el-icon :size="24">
                        <SwitchButton />
                    </el-icon>
                </div>
                <div class="line-text" @click="outLoginFn">退出系统</div>
            </div>
        </div>
        <validityTime ref="validityTimeRef"></validityTime>
    </div>
</template>
<script>
import QrcodeVue from "qrcode.vue";
import audioVue from "@/components/audio.vue";
import { create, getImg } from "@/api/wxwarning";
import { findUserInfoDetails } from "@/api/media.js";
import validityTime from "./validityTime.vue";
export default {
    components: {
        QrcodeVue,
        audioVue, validityTime
    },
    name: "tHeader",
    data() {
        return {
            activeIndex: "0",
            openSidebar: true,
            paddingLeft: 240,
            userInfo: null,
            value: window.location.origin + "/appDownload",
            size: 165,
            pathUrl: null,
            isWps: false,
        };
    },
    watch: {
        "$store.state.userInfo": {
            handler: function () {
                this.userInfo = this.$store.state.userInfo;
            },
        },
    },
    created() {

        this.$store.commit("setUserInfo", localStorage.getItem("USERINFO"));
        this.userInfo = this.$store.state.userInfo;
        if (!localStorage.getItem('timeVisible')) {
            this.checkTimeDifference();
        }
        this.$store.dispatch("getfindDomainDetailsFn");
        // this.getCreateFn();
        // this.findUserInfoDetailsFn();
        try {
            if (this.userInfo.host_url.indexOf(process.env.VUE_APP_BASE_WPS) != -1) {
                this.isWps = true;
            } else {
                this.isWps = false;
            }
        } catch (error) { }
    },
    methods: {
        handleSelect() {
            this.openSidebar = !this.openSidebar;
            if (this.openSidebar) {
                this.paddingLeft = 240;
                this.$emit("setPaddingFn", 260);
                this.$parent.$refs.sidebar.width = 240;
            } else {
                this.paddingLeft = 0;
                this.$emit("setPaddingFn", 20);
                this.$parent.$refs.sidebar.width = 0;
            }
        },
        outLoginFn() {
            this.$store.dispatch("loginOutFn");
        },
        /**获取二维码参数 */
        getCreateFn() {
            create({
                account: this.$store.state.userInfo.id,
            }).then((res) => {
                getImg({
                    ticket: JSON.parse(res.data).ticket,
                }).then((reson) => {
                    this.pathUrl = reson.data.pathUrl;
                });
            });
        },
        /**获取用户信息 */
        findUserInfoDetailsFn() {
            findUserInfoDetails({ uid: this.$store.state.userInfo.id }).then(
                (res) => {
                    let data = JSON.parse(res.userInfoDetails);
                    this.$store.commit("setBangding", {
                        binding_status: data.binding_status,
                        union_id: data.union_id,
                    });
                }
            );
        },
        wpsDow() {
            window.open("https://www.wengaowuyou.com/wps/download");
        },
        /**判断是否不足30天 */
        checkTimeDifference() {
            const thirtyDaysInSeconds = 30 * 24 * 60 * 60;
            let currentTimestamp = Math.floor(Date.now() / 1000); // 当前时间戳，转换为秒
            const timeDifference = this.userInfo.validity_time / 1000 - currentTimestamp;
            let isLessThan30Days = timeDifference < thirtyDaysInSeconds;
            if (isLessThan30Days) {
                this.$store.commit("setVisible", true);
                this.$store.commit("setVisibleNumber", Math.floor(timeDifference / (24 * 60 * 60)));
                localStorage.setItem('timeVisible', 1)
            }
        }
    },
};
</script>
<style lang="less" scoped>
.t-header {
    height: 54px;
    width: 100%;
    background: @color424e59;
    box-sizing: border-box;
    padding-right: 20px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1999;

    .header-logo {
        position: absolute;
        height: 54px;
        width: 240px;
        left: 0;
        top: 0;
        text-align: center;
        // background: @color2e2e2e;
        line-height: 54px;

        /deep/.el-image__inner {
            max-width: 90%;
            max-height: 48px;
            vertical-align: middle;
        }
    }

    .header-icon {
        padding-left: 20px;
        padding-top: 11px;
        cursor: pointer;

        .header-icon-list {
            color: #fff;
            line-height: 32px;
            margin-left: 30px;
            .font-14();
            cursor: pointer;
            position: relative;
        }
    }

    .header-box {
        // line-height: 54px;

        .box-line {
            color: #fff;
            padding-left: 32px;

            .line-icon {
                padding-top: 15px;
            }

            .line-text {
                line-height: 54px;
                padding-left: 10px;
                .font-14();
                cursor: pointer;
            }
        }
    }
}

/**广东联通 */
.gdlt {
    .t-header {
        background: @colorfff;

        .header-logo {
            background: @colorfff;
        }

        .header-icon {
            padding-left: 20px;
            padding-top: 11px;
            cursor: pointer;

            .el-icon {
                color: @color424e59;
            }
        }

        .header-box {
            .box-line {
                color: @color424e59;
            }
        }
    }
}

/**移动 */
.yidong {
    .t-header {
        background: @colorfff;
        border-bottom: 2px solid @color81cfff;

        .header-icon {
            padding-left: 20px;
            padding-top: 11px;
            cursor: pointer;

            .el-icon {
                color: @color81cfff;
            }
        }

        .header-box {
            .box-line {
                color: @color81cfff;
            }
        }
    }
}
</style>
