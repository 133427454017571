<template>
  <div class="layout" ref="layout">
    <tHeader ref="header" @setPaddingFn="setPaddingFn"></tHeader>
    <tSidebar ref="sidebar"></tSidebar>
    <div
      class="layout-router animation-All"
      :style="{ 'padding-left': paddingLeft + 'px' }"
    >
      <router-view />
    </div>
  </div>
</template>
<script>
import tHeader from "./components/tHeader";
import tSidebar from "./components/tSidebar";
export default {
  name: "tLayout",
  components: {
    tHeader,
    tSidebar,
  },
  data() {
    return {
      paddingLeft: 260,
    };
  },
  mounted() {
    if (this.$domain.gdlt) {
      // 广东联通
      this.$refs.layout.classList.add("gdlt");
    } else if (this.$domain.yidong) {
      // 移动
      this.$refs.layout.classList.add("yidong");
    }
  },
  methods: {
    setPaddingFn(val) {
      this.paddingLeft = val;
    },
  },
};
</script>

<style lang="less" scoped>
.layout-router {
  padding-top: 74px;
  padding-right: 20px;
  padding-bottom: 20px;
}
</style>