import { createApp, reactive } from 'vue'
import App from './App.vue'

import router from './router' // 路由

import ElementPlus from 'element-plus' // element组件
import 'element-plus/dist/index.css'
import common from './utils/common' // 公共方法
import store from './store' // 公共参数
import domain from './utils/domain'// 域名
import cookie from './utils/cookie';
import VueUeditorWrap from 'vue-ueditor-wrap';
// 如果您正在使用CDN引入，请删除下面一行。
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import VueClipBoard from 'vue-clipboard2'
import * as echarts from 'echarts'
import vuetyped from 'vue3typed'

// 引入NProgress
import NProgress from 'nprogress'
// 引入nprogress样式文件
import 'nprogress/nprogress.css'
import md5 from 'js-md5';
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
const DOMIAN = window.location.origin;
router.beforeEach((to, from, next) => {
    if (DOMIAN == 'https://www.wengaowuyou.com' || DOMIAN == 'http://www.wengaowuyou.com' || DOMIAN == 'https://wengaowuyou.com') {
        document.title = '文稿无忧'
    } else if (DOMIAN == 'https://gdlt.ai-wgt.com' || DOMIAN == 'https://gdlt.kimkong.cn') {
        document.title = '数御·金刚'
    } else if (DOMIAN == 'http://www.zhuyijiaodui.com') {
        document.title = '逐一校对'
    } else if (DOMIAN == 'https://10010.ai-wgt.com') {
        document.title = 'AI智能校对'
    } else if (DOMIAN == 'https://www.10086wgt.com') {
        document.title = '灵犀AI文稿通'
    } else if (to.meta.title) {
        document.title = to.meta.title; // 通过document.title赋值
    }
    if (to.name == undefined) {
        next('/index');
    } else {
        next()
    }
});
 
app.use(vuetyped)
app.use(router);
app.use(ElementPlus);
app.use(VueUeditorWrap);
app.use(VueClipBoard);
app.config.globalProperties.$common = common;
app.config.globalProperties.$store = store;
app.config.globalProperties.$cookie = cookie;
app.config.globalProperties.$domain = domain;
app.config.globalProperties.$reactive = reactive;
app.config.globalProperties.$echarts = echarts // 全局挂载echarts
app.config.globalProperties.$NProgress = NProgress
app.config.globalProperties.$md5 = md5
app.mount('#app')
