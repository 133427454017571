import request from '@/utils/request'
// 繁简转化
export function ctctrans(data) { return request({ url: '/api_textError/ctc-trans', method: 'post', data: data }) }

// 咪咕语音识别
export function miguMusic(data) { return request({ url: '/api_migu/miguMusic', method: 'post', data: data }) }

// 翻译
export function translate(data) { return request({ url: '/api_textError/translate', method: 'post', data: data }) }

// 分类查询接口
export function category(data) { return request({ url: '/api/escr/category', method: 'post', data: data }) }

// 内容搜索接口
export function search(data) { return request({ url: '/api/escr/search', method: 'post', data: data }) }

// 泽华的ocr图片识别
export function picCOfPXj(data) { return request({ url: '/api_tp/picCOfPXj', method: 'post', data: data }) }

