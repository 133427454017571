
import { createRouter, createWebHistory } from 'vue-router'
import Layout from "@/layout";
const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/', //换成''效果一样
            name: "index",
            component: () => require.ensure([], (require) => require('@/views/index/index.vue')),
            meta: { title: '首页' },
            hidden: true
        },
        {
            path: '/index',
            name: "login",
            component: () => require.ensure([], (require) => require('@/views/index/index.vue')),
            meta: { title: '首页' },
            hidden: true
        },
        {
            path: "/redirect", // 公共配置
            component: Layout,
            hidden: true,
            children: [
                {
                    path: "/redirect/:path(.*)",
                    component: (resolve) => require(["@/views/redirect"], resolve)
                },
            ]
        },
        {
            path: "/",
            component: Layout,
            redirect: "/proofreading/text",
            children: [{
                path: 'proofreading/text',
                name: "proofreading_text",
                component: () => require.ensure([], (require) => require('@/views/proofreading/proofreading_text')),
                meta: { title: '文本校对', leftPitch: 0 },
            }, {
                path: 'proofreading/img',
                name: "proofreading_img",
                component: () => require.ensure([], (require) => require('@/views/proofreading/proofreading_text')),
                meta: { title: '图片校对', leftPitch: 1 },
            }, {
                path: 'proofreading/fileList',
                name: "fileList",
                component: () => require.ensure([], (require) => require('@/views/fileList/fileList')),
                meta: { title: '文件列表' },
            }, {
                path: 'proofreading/inspiration',
                name: "proofreading_inspiration",
                component: () => require.ensure([], (require) => require('@/views/inspiration/index.vue')),
                meta: { title: 'AI灵感' },
            },
            {
                path: '/proofreading/communication',
                name: "communicationPc",
                component: () => require.ensure([], (require) => require('@/views/communication_page/index_ip.vue')),
                meta: { title: '文章仿写' },
            },
            {
                path: 'proofreading/contentComparison',
                name: "proofreading_contentComparison",
                component: () => require.ensure([], (require) => require('@/views/contentComparison/index.vue')),
                meta: { title: '内容差异对比' },
            }, {
                path: 'lexicon/lingdaoren',
                name: "lingdaoren",
                component: () => require.ensure([], (require) => require('@/views/lexiconList/lingdaoren/lingdaoren')),
                meta: { title: '领导人词库' },
            }, {
                path: 'lexicon/baodaoshunxu',
                name: "baodaoshunxu",
                component: () => require.ensure([], (require) => require('@/views/lexiconList/baodaoshunxu/baodaoshunxu')),
                meta: { title: '报道顺序' },
            }, {
                path: 'lexicon/zhengci',
                name: "zhengci",
                component: () => require.ensure([], (require) => require('@/views/lexiconList/zhengci/zhengci')),
                meta: { title: '正词词库' },
            }, {
                path: 'lexicon/cuoci',
                name: "cuoci",
                component: () => require.ensure([], (require) => require('@/views/lexiconList/cuoci/cuoci')),
                meta: { title: '错词词库' },
            }, {
                path: 'lexicon/jinyongci',
                name: "jinyongci",
                component: () => require.ensure([], (require) => require('@/views/lexiconList/jinyongci/jinyongci')),
                meta: { title: '禁用词词库' },
            }, {
                path: 'lexicon/zhongdianguanzhu',
                name: "zhongdianguanzhu",
                component: () => require.ensure([], (require) => require('@/views/lexiconList/zhongdianguanzhu/zhongdianguanzhu')),
                meta: { title: '重点关注词库' },
            }, {
                path: 'lexicon/fengxianrenyuan',
                name: "fengxianrenyuan",
                component: () => require.ensure([], (require) => require('@/views/lexiconList/fengxianrenyuan/fengxianrenyuan')),
                meta: { title: '风险人员词库' },
            }, {
                path: 'media/overView',
                name: "overView",
                component: () => require.ensure([], (require) => require('@/views/overView/overView')),
                meta: { title: '总览' },
            }, {
                path: 'media/pollingSource',
                name: "pollingSource",
                component: () => require.ensure([], (require) => require('@/views/pollingSource/pollingSource')),
                meta: { title: '巡检源管理' },
            }, {
                path: 'media/systemSetup',
                name: "systemSetup",
                component: () => require.ensure([], (require) => require('@/views/systemSetup/systemSetup')),
                meta: { title: '系统设置' },
            }, {
                path: 'media/tbgz',
                name: "tbgz",
                component: () => require.ensure([], (require) => require('@/views/pollingList/table_001_tbgz/index')),
                meta: { title: '特别关注' },
            }, {
                path: 'media/nrgx',
                name: "nrgx",
                component: () => require.ensure([], (require) => require('@/views/pollingList/table_002_nrgx/index')),
                meta: { title: '内容更新' },
            }, {
                path: 'media/aqgj',
                name: "aqgj",
                component: () => require.ensure([], (require) => require('@/views/pollingList/table_003_aqgj/index')),
                meta: { title: '安全攻击' },
            }, {
                path: 'media/fwyc',
                name: "fwyc",
                component: () => require.ensure([], (require) => require('@/views/pollingList/table_004_fwyc/index')),
                meta: { title: '访问异常' },
            }, {
                path: 'media/nrjc',
                name: "nrjc",
                component: () => require.ensure([], (require) => require('@/views/pollingList/table_005_nrjc/index')),
                meta: { title: '内容检测' },
            }, {
                path: 'media/fjjc',
                name: "fjjc",
                component: () => require.ensure([], (require) => require('@/views/pollingList/table_006_fjjc/index')),
                meta: { title: '附件检测' },
            },
            // 常见工具
            {
                path: 'tool/pdf&word',
                name: "pdf&word",
                component: () => require.ensure([], (require) => require('@/views/tool/pdf&word/index')),
                meta: { title: '文档转化' },
            }, {
                path: 'tool/audio&txt',
                name: "audio&txt",
                component: () => require.ensure([], (require) => require('@/views/tool/audio&txt/index')),
                meta: { title: '语音听写' },
            }, {
                path: 'tool/img&watermark',
                name: "img&watermark",
                component: () => require.ensure([], (require) => require('@/views/tool/img&watermark/index')),
                meta: { title: '水印添加' },
            }, {
                path: 'tool/complex&familiar',
                name: "complex&familiar",
                component: () => require.ensure([], (require) => require('@/views/tool/complex&familiar/index')),
                meta: { title: '繁简转换' },
            }, {
                path: 'tool/multilingual&translation',
                name: "multilingual&translation",
                component: () => require.ensure([], (require) => require('@/views/tool/multilingual&translation/index')),
                meta: { title: '中外互译' },
            }]
        },
        {
            path: '/tbgz/examine',
            name: "tbgzExamine",
            component: () => require.ensure([], (require) => require('@/views/pollingList/table_001_tbgz/examine.vue')),
            meta: { title: '特别关注详情' },
            hidden: true
        },
        {
            path: '/nrgx/examine',
            name: "nrgxExamine",
            component: () => require.ensure([], (require) => require('@/views/pollingList/table_002_nrgx/examine.vue')),
            meta: { title: '内容更新详情' },
            hidden: true
        },
        {
            path: '/nrjc/examine',
            name: "nrjcExamine",
            component: () => require.ensure([], (require) => require('@/views/pollingList/table_005_nrjc/examine.vue')),
            meta: { title: '内容检测详情' },
            hidden: true
        },
        {
            path: '/fjjc/examine',
            name: "fjjcExamine",
            component: () => require.ensure([], (require) => require('@/views/pollingList/table_006_fjjc/examine.vue')),
            meta: { title: '附件检测详情' },
            hidden: true
        },
        {
            path: '/searching',
            name: "searching",
            component: () => require.ensure([], (require) => require('@/views/searching/index.vue')),
            meta: { title: '内容检索' },
            hidden: true
        },
        {
            path: '/appDownload',
            name: "appDownload",
            component: () => require.ensure([], (require) => require('@/views/app/appDownload.vue')),
            meta: { title: 'APP下载' },
            hidden: true
        }
    ],
})
export default router;