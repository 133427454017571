import request from '@/utils/request'

// 获取微信公众号二维码参数的接口
export function create(data) { return request({ url: '/api/WxAuthApi/create', method: 'post', data: data }) }

// 获取微信公众号二维码图片的接口
export function getImg(data) { return request({ url: '/api/WxAuthApi/getImg', method: 'post', data: data }) }

// 给用户发送微信模板消息接口
export function sendWeChatMessage(data) { return request({ url: '/api/wechat/sendWeChatMessage', method: 'post', data: data }) }

