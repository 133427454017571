<template>
  <div class="sidebar animation-All" :style="{ width: width + 'px' }">
    <el-menu
      v-if="sidebar"
      active-text-color="#ffd04b"
      background-color="#424e59"
      :unique-opened="true"
      class="el-menu-vertical-demo"
      :default-active="defaultActiv"
      text-color="#fff"
      router
      @open="handleOpen"
      @close="handleClose"
    >
      <div v-for="item in sidebar" :key="item.name">
        <el-sub-menu
          :index="item.url"
          @click="menuFn"
          v-if="item.show && item.children.length > 0"
        >
          <template #title>
            <component class="icons icons-font" :is="item.icon"></component>
            <span v-html="item.name"></span>
          </template>
          <div v-for="itemOne in item.children" :key="itemOne.name">
            <el-sub-menu :index="itemOne.url" v-if="itemOne.children.length">
              <template #title>
                <component
                  class="icons icons-font"
                  :is="itemOne.icon"
                ></component>
                <span v-html="itemOne.name"></span>
              </template>
              <div v-for="itemTwo in itemOne.children" :key="itemTwo.name">
                <el-sub-menu
                  :index="itemTwo.url"
                  v-if="itemTwo.children.length"
                >
                  <template #title>
                    <component
                      class="icons icons-font"
                      :is="itemTwo.icon"
                    ></component>
                    <span v-html="itemTwo.name"></span>
                  </template>
                </el-sub-menu>
                <el-menu-item :index="itemTwo.url" v-else>
                  <span v-html="itemTwo.name"></span>
                  <!-- 提示 -->
                  <el-popover
                    placement="top-start"
                    title="系统提示"
                    :width="300"
                    trigger="hover"
                    :content="itemTwo.msg"
                  >
                    <template #reference>
                      <el-icon v-if="itemTwo.msg">
                        <InfoFilled />
                      </el-icon>
                    </template>
                  </el-popover>
                  <img
                    src="../../assets/svg/new_yidong.svg"
                    width="30"
                    alt=""
                    v-if="itemTwo.new && this.$domain.yidong"
                  />
                  <img
                    src="../../assets/svg/new.svg"
                    v-else-if="itemTwo.new"
                    width="30"
                    alt=""
                  />
                </el-menu-item>
              </div>
            </el-sub-menu>
            <el-menu-item :index="itemOne.url" v-else>
              <span v-html="itemOne.name"></span>
            </el-menu-item>
          </div>
        </el-sub-menu>

        <el-menu-item :index="item.url" v-else-if="item.show && !$domain.gdlt">
          <span v-html="item.name"></span>
        </el-menu-item>
      </div>
    </el-menu>
  </div>
</template>
<script>
import sidebar from "./sidebar.js";
export default {
  name: "tSidebar",
  data() {
    return {
      sidebar: sidebar,
      width: 240,
      defaultActiv: "",
    };
  },
  created() {
    this.userInfo = this.$store.state.userInfo;
    this.setSidebarFn();
    /**设置目录定位 */
    this.$store.commit("setDefaultActiv", this.$route.fullPath);
    this.defaultActiv = this.$store.state.defaultActiv;
    // if (this.$domain.aiduibi) {
    //   let status = false;
    //   this.sidebar[0].children.forEach((item) => {
    //     if (item.name == "AI灵感") {
    //       // if (item.name == 'AI灵感'|| item.name == '内容对比差异') {
    //       status = true;
    //     }
    //   });
    //   if (!status) {
    //     this.sidebar[0].children.push(
    //       {
    //         name: "AI灵感",
    //         icon: "",
    //         url: "/proofreading/inspiration",
    //         msg: "",
    //         children: [],
    //         new: true,
    //       }
    //       // {
    //       //   name: "内容差异对比",
    //       //   icon: "",
    //       //   url: "/proofreading/contentComparison",
    //       //   msg: "",
    //       //   children: [],
    //       // }
    //     );
    //   }
    // }
  },
  methods: {
    /**根据不同的级别显示不同的目录 */
    setSidebarFn() {
      let customer_level = this.$store.state.userInfo.customer_level;
      console.log(customer_level);
      this.sidebar[0].show = false;
      this.sidebar[2].show = false;
      this.sidebar[3].show = false;
      this.sidebar[1].show = false;
      if (customer_level == 1) {
        // 巡检页面
        this.sidebar[1].show = true;
      } else if (customer_level == 2) {
        // 内容校对页面
        this.sidebar[0].show = true;
      } else if (customer_level == 3) {
        // 内容校对和巡检
        this.sidebar[0].show = true;
        this.sidebar[1].show = true;
      } else if (customer_level == 4) {
        // 常见工具
        this.sidebar[2].show = true;
      } else if (customer_level == 5) {
        // 网站巡检 常见工具
        this.sidebar[2].show = true;
        this.sidebar[1].show = true;
      } else if (customer_level == 6) {
        this.sidebar[0].show = true;
        this.sidebar[2].show = true;
      } else if (customer_level == 7) {
        this.sidebar[0].show = true;
        this.sidebar[2].show = true;
        this.sidebar[1].show = true;
      } else if (customer_level == 8) {
        this.sidebar[3].show = true;
      } else if (customer_level == 9) {
        this.sidebar[1].show = true;
        this.sidebar[3].show = true;
      } else if (customer_level == 10) {
        this.sidebar[0].show = true;
        this.sidebar[3].show = true;
      } else if (customer_level == 11) {
        this.sidebar[2].show = true;
        this.sidebar[3].show = true;
      } else if (customer_level == 12) {
        this.sidebar[0].show = true;
        this.sidebar[1].show = true;
        this.sidebar[3].show = true;
      } else if (customer_level == 13) {
        this.sidebar[2].show = true;
        this.sidebar[1].show = true;
        this.sidebar[3].show = true;
      } else if (customer_level == 14) {
        this.sidebar[2].show = true;
        this.sidebar[0].show = true;
        this.sidebar[3].show = true;
      } else if (customer_level == 15) {
        this.sidebar[2].show = true;
        this.sidebar[0].show = true;
        this.sidebar[1].show = true;
        this.sidebar[3].show = true;
      }

    //   if (!this.$domain.gdlt) {
    //     this.sidebar[3].show = true;
    //   }
      // if(!suobei){
      //  this.sidebar[0].show = false;
      // this.sidebar[2].show = false;
      // this.sidebar[1].show = true;
      // }
    },
    handleOpen() {},
    handleClose() {},
    menuFn() {
      this.$store.commit("setDefaultActiv", this.$route.path);
    },
  },
  watch: {
    "$store.state.defaultActiv": {
      handler: function () {
        this.defaultActiv = this.$store.state.defaultActiv;
      },
    },
  },
};
</script>
<style lang="less" scoped>
.sidebar {
  width: 240px;
  left: 0;
  padding-top: 54px;
  position: fixed;
  height: 100vh;
  background: @color424e59;
  box-sizing: border-box;
  overflow: hidden;
}

.icons-font {
  width: 16px;
  margin-right: 3px;
}

.gdlt {
  .sidebar {
    background: @colorfff;
    border-right: 1px solid #dcdfe6;

    .el-menu {
      background: @colorfff;
      border-right: none;
      /deep/.el-sub-menu__title {
        background: @colorfff!important;
        color: @colorf60!important;
      }
      /deep/.el-menu {
        background: @colorfff!important;
        color: @colorf60!important;
      }
      /deep/.el-menu-item {
        background: @colorfff!important;
        color: @colorf60!important;
      }

      /deep/ .el-menu-item.is-active {
        background: @colorf60!important;
        color: @colorfff!important;
      }
    }
  }
}

.yidong {
  .sidebar {
    background: @colorfff;
    border-right: 1px solid #dcdfe6;

    .el-menu {
      background: @colorfff;
      border-right: none;
      /deep/.el-sub-menu__title {
        background: @colorfff!important;
        color: @color81cfff!important;
      }
      /deep/.el-menu {
        background: @colorfff!important;
        color: @color81cfff!important;
      }
      /deep/.el-menu-item {
        background: @colorfff!important;
        color: @color81cfff!important;
      }

      /deep/ .el-menu-item.is-active {
        background: @color81cfff!important;
        color: @colorfff!important;
      }
    }
  }
}
</style>
>
