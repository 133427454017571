import request from '@/utils/request'

// 获取 巡检总览特别关注、内容更新、安全攻击、内容检测、访问异常总数
export function findAmountByCondition(data) { return request({ url: '/api_mo/findAmountByCondition', method: 'post', data: data }) }

// 获取信源数据概览
export function findTendencyByCondition(data) { return request({ url: '/api_mo/findTendencyByCondition', method: 'post', data: data }) }

// 获取信源
export function findMSTotalNum(data) { return request({ url: '/api_mo/findMSTotalNum', method: 'post', data: data }) }

// 获取特别关注
export function findFocusOnSpecialList(data) { return request({ url: '/api_mr/findFocusOnSpecialList', method: 'post', data: data }) }

// 获取更新文章列表
export function findUtaLst(data) { return request({ url: '/api_mo/findUtaLst', method: 'post', data: data }) }

// 获取访问异常列表
export function findAeLst(data) { return request({ url: '/api_mo/findAeLst', method: 'post', data: data }) }

// 获取安全攻击列表
export function findSecurityAttacksLst(data) { return request({ url: '/api_mo/findSecurityAttacksLst', method: 'post', data: data }) }

// 获取内容安全列表
export function findCsGraph(data) { return request({ url: '/api_mo/findCsGraph', method: 'post', data: data }) }

// 获取错别字词云
export function findGhostWordLst(data) { return request({ url: '/api_mo/findGhostWordLst', method: 'post', data: data }) }

// 获取敏感词词云
export function findSensitiveWordLst(data) { return request({ url: '/api_mo/findSensitiveWordLst', method: 'post', data: data }) }

// 获取巡检源管理列表 查询
export function findMSList(data) { return request({ url: '/api_ms/findMSList', method: 'post', data: data }) }

// 删除巡检源管理 删除
export function deleteMonitoringSource(data) { return request({ url: '/api_ms/deleteMonitoringSource', method: 'post', data: data }) }

// 新增巡检源管理 新增
export function addMonitoringSource(data) { return request({ url: '/api_ms/addMonitoringSource', method: 'post', data: data }) }

// 修改巡检源管理 修改
export function updateMonitoringSource(data) { return request({ url: '/api_ms/updateMonitoringSource', method: 'post', data: data }) }

// 查看可用信源
export function findUserInfoDetails(data) { return request({ url: '/api_user/findUserInfoDetails', method: 'post', data: data }) }

// 巡检源 批量上传
export function addMonitoringSourceList(data) { return request({ url: '/api_ms/addMonitoringSourceList', method: 'post', data: data }) }

// 获取预警设置
export function findWarningDetails(data) { return request({ url: '/api_warning/findWarningDetails', method: 'post', data: data }) }

// 保存预警设置
export function addWarning(data) { return request({ url: '/api_warning/addWarning', method: 'post', data: data }) }

// 修改预警设置
export function updateWarning(data) { return request({ url: '/api_warning/updateWarning', method: 'post', data: data }) }

// 关闭预警设置
export function delectWarning(data) { return request({ url: '/api_warning/delectWarning', method: 'post', data: data }) }


// 微信预警
export function create(data) { return request({ url: '/api/WxAuthApi/create', method: 'post', data: data }) }
